import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import gridFilterModal from './GridFilterModal.styles';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIcon from '../../../packages/common/shared-ui/src/icons/DeleteIcon';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import DragDropLists from '../../../packages/common/shared-ui/src/components/DragDropLists';
import { getTranslatedText } from '../../../packages/common/utils/getTranslatedText';

const GridFiltersModal = ({ intl, open, data, selectedFilters, handleSave, handleClose, handleClear }) => {
  const classes = gridFilterModal();
  const [modalData, setModalData] = useState(null);
  const [currentModalData, setCurrentModalData] = useState({ filters: [] });
  const [currentModalFields, setCurrentModalFields] = useState({ availableFields: [], selectedFields: [] });

  useEffect(() => {
    if (selectedFilters && selectedFilters.length > 0) {
      setCurrentModalData({ ...currentModalData, filters: selectedFilters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);
  useEffect(() => {
    if (data) {
      data.fields &&
        setCurrentModalFields({
          availableFields: data.fields.filter(item => !item.isChecked),
          selectedFields: data.fields.filter(item => item.isChecked),
        });
      setModalData(data);
    }
  }, [data]);
  const handleChangeGroup = useCallback(event => {
    setCurrentModalData(prev => {
      return { ...prev, groups: event.target.value };
    });
  }, []);
  const handleSaveButton = useCallback(() => {
    const data = {
      ...currentModalData,
      fields: currentModalFields,
    };
    if (currentModalData) {
      handleSave(data);
    }
  }, [currentModalData, currentModalFields, handleSave]);
  const handleClearButton = useCallback(() => {
    const values = {
      filters: [],
      fields: {
        availableFields: data.fields.filter(item => !item.isChecked),
        selectedFields: data.fields.filter(item => item.isChecked),
      },
    };
    setCurrentModalData({ filters: [] });
    setCurrentModalFields({
      availableFields: data.fields.filter(item => !item.isChecked),
      selectedFields: data.fields.filter(item => item.isChecked),
    });
    handleClear(values);
  }, [data.fields, handleClear]);
  const handleSelectFilters = useCallback(
    value => {
      setCurrentModalData({ ...currentModalData, filters: value });
    },
    [currentModalData],
  );
  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth={true}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={8.5}>
        <Typography variant="h1">
          <FormattedMessage id="parameters_text" />
        </Typography>
        <IconButton disableRipple value="close" onClick={handleClose}>
          <Box width={24} height={24}>
            <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
          </Box>
        </IconButton>
      </Grid>
      <Grid container direction="column">
        {/*        {modalData && modalData.groups && (
          <Grid container alignItems="center" wrap="nowrap" paddingLeft={3.5} marginBottom={5}>
            <Grid item xs={5}>
              <Typography variant="h5">
                <FormattedMessage id="group" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                variant="outlined"
                onChange={e => handleChangeGroup(e)}
                //value={modalSessionParams.groups}
                IconComponent={() => (
                  <Box className={classes.groupIcon}>
                    <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                  </Box>
                )}
                endAdornment={
                  <IconButton
                    disableRipple
                    //sx={{ display: modalSessionParams.groups === 0 ? 'none' : '' }}
                    //onClick={() => handleChangeGroup({ clear: true })}
                  >
                    <ClearIcon viewBox="0 0 24 24" color="primary" />
                  </IconButton>
                }
                MenuProps={{ className: classes.optionsGroupMenu }}
                color="secondary"
              >
                {modalData.groups.map(item => (
                  <MenuItem value={item.fieldId} key={item.fieldId}>
                    <Typography variant="h5" color="#212346">
                      {item.fieldName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}*/}
        {modalData && modalData.filters && (
          <Grid container paddingLeft={3.5} height={'100%'}>
            <Grid item xs={5}>
              <Typography variant="h5">
                <FormattedMessage id="filter" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                className={classes.multipleAutocomplete}
                multiple
                disableClearable
                //open={true}
                onChange={(event, newValue) => handleSelectFilters(newValue)}
                options={modalData.filters}
                disableCloseOnSelect
                disablePortal
                value={currentModalData.filters}
                isOptionEqualToValue={(option, value) => option.fieldId === value.fieldId}
                getOptionLabel={option => option.fieldName}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.fieldId}>
                    <Checkbox
                      icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                      checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {intl.formatMessage({
                      id: `confirmations_page_${option.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                    })}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                      className={classes.chip}
                      variant="outlined"
                      label={intl.formatMessage({
                        id: `confirmations_page_${option.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
                      })}
                    />
                  ))
                }
                renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
              />
            </Grid>
          </Grid>
        )}
        {modalData && modalData.fields && (
          <DragDropLists
            intl={intl}
            firstListOptions={currentModalFields.availableFields}
            secondListOptions={currentModalFields.selectedFields}
            setParentState={setCurrentModalFields}
            getOptionLabel={option =>
              `${option.fieldId};${getTranslatedText(intl, 'filter', option.fieldId, option.fieldName)}`
            }
          />
        )}
        <Grid container justifyContent="center" marginTop={5}>
          <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearButton}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
            </Typography>
          </Button>
          <Box marginLeft={1}>
            <Button disableRipple variant="cutBlueSecondary" onClick={handleSaveButton}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="save" defaultMessage="Clear" />
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(GridFiltersModal);
